import { JobStatus } from '@doc-abode/data-models';
import moment from 'moment';

import { getMidnightDayString } from '../../../components/modules/helpers/formatData';
import { FormFieldRecord } from '../jobCreator';
import { JobType } from './JobTypes';

export const hasIdValuesChanged = (
    values: any,
    isBuddy: boolean,
    isCreating: boolean = false,
    isFollowUp: boolean = false,
) => {
    const status: JobStatus | undefined = values[isBuddy ? 'buddyJobStatus' : 'jobStatus'];
    const staffMember = values[isBuddy ? 'buddyId' : 'hcpId'];
    let hasStaffMemberChanged: boolean = false;

    if (values['_additionalValues']) {
        hasStaffMemberChanged =
            values['_additionalValues'][
                isBuddy ? 'staffMemberWasChangedBuddy' : 'staffMemberWasChanged'
            ];
    }

    if (isCreating || isFollowUp) {
        return staffMember ? JobStatus.ACCEPTED : JobStatus.PENDING;
    }

    if (!hasStaffMemberChanged && status) {
        return status;
    }

    if (hasStaffMemberChanged) {
        return JobStatus.ACCEPTED;
    }

    if (staffMember) {
        return JobStatus.ACCEPTED;
    }

    return JobStatus.PENDING;
};

export const genericSchema: FormFieldRecord[] = [
    {
        key: 'version',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return !values.version ? 0 : values.version + 1;
        },
    },
    {
        key: 'jobType',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: () => {
            return 'ucr';
        },
    },
    {
        key: 'lastUpdatedBy',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return values['_additionalValues']?.username;
        },
    },
    {
        key: 'organisation',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return values['_additionalValues']?.org ?? null;
        },
    },
    { key: 'duration', followUpCopy: [JobType.ADMIN, JobType.VISIT] },
    {
        key: 'notes',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return values.notes ? values.notes : '';
        },
    },
    { key: 'postVisitNotes', followUpCopy: [] },
    {
        key: 'id',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values['_additionalValues']?.id ?? undefined;
        },
    },
    {
        key: 'createDateTime',
        onCreateOnly: true,
        followUpCopy: [],
        transformValue: () => {
            return moment().toISOString();
        },
    },
    {
        key: 'createdBy',
        onCreateOnly: true,
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values['_additionalValues']?.username;
        },
    },
    {
        key: 'visitDate',
        followUpCopy: [],
        transformTo: 'startDateTime',
        transformValue: (props) => {
            const { values } = props;
            if (!values['startTime']) {
                return null;
            }

            const startTime = moment(values['startTime']);
            return moment(values['visitDate'])
                .hour(startTime.hour())
                .minute(startTime.minute())
                .seconds(0)
                .milliseconds(0)
                .toISOString();
        },
    },
    {
        key: 'visitDate',
        followUpCopy: [],
        transformTo: 'dateOfVisit',
        transformValue: (props) => {
            const { values } = props;
            return getMidnightDayString(moment(values['visitDate']));
        },
    },
    {
        key: 'hcpId',
        followUpCopy: [],
        isAnIndex: true,
        transformValue: (props) => {
            const { values } = props;
            return values.hcpId ?? null;
        },
    },
    {
        key: 'hcpName',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            if (!values.hcpId) {
                return null;
            }

            return (
                values['_additionalValues']?.users?.find(
                    (user: any) => values.hcpId === user.userId,
                )?.userName ?? null
            );
        },
    },
    {
        key: 'buddyId',
        followUpCopy: [],
        isAnIndex: true,
        transformValue: (props) => {
            const { values } = props;
            return values.buddyId ?? null;
        },
    },
    {
        key: 'buddyJobStatus',
        isAnIndex: true,
        followUpCopy: [],
        transformValue: (props) => {
            const { values, onCreate, followUp } = props;
            if (values.staffRequired < 2) {
                return null;
            }
            return hasIdValuesChanged(values, true, onCreate, followUp);
        },
    },
    {
        key: 'buddyName',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            if (!values.buddyId) {
                return null;
            }

            return (
                values['_additionalValues']?.users?.find(
                    (user: any) => values?.buddyId === user.userId,
                )?.userName ?? null
            );
        },
    },
    {
        key: 'jobStatus',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        isAnIndex: true,
        transformValue: (props) => {
            const { values, onCreate, followUp } = props;
            return hasIdValuesChanged(values, false, onCreate, followUp);
        },
    },

    {
        key: 'madeCurrentDateTime',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.madeCurrentDateTime
                ? moment(values.madeCurrentDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyMadeCurrentDateTime',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyMadeCurrentDateTime
                ? moment(values.buddyMadeCurrentDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'arrivedDateTime',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.arrivedDateTime ? moment(values.arrivedDateTime).toISOString() : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyArrivedDateTime',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyArrivedDateTime
                ? moment(values.buddyArrivedDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyHcpAbortedDateTime',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyHcpAbortedDateTime
                ? moment(values.buddyHcpAbortedDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'hcpAbortedDateTime',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.hcpAbortedDateTime
                ? moment(values.hcpAbortedDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyFinishedDateTime',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyFinishedDateTime
                ? moment(values.buddyFinishedDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'finishedDateTime',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.finishedDateTime ? moment(values.finishedDateTime).toISOString() : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyControllerAbortedNotes',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyControllerAbortedNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyControllerAbortedReason',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyControllerAbortedReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyControllerReverseNotes',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyControllerReverseNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyControllerReverseReason',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyControllerReverseReason ?? null;
        },
        onUpdateOnly: true,
    },

    {
        key: 'buddyHcpAbortedNotes',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyHcpAbortedNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyHcpAbortedReason',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyHcpAbortedReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyHcpReverseNotes',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyHcpReverseNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyHcpReverseReason',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyHcpReverseNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'controllerAbortedNotes',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.controllerAbortedNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'controllerAbortedReason',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.controllerAbortedReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'controllerReverseNotes',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.controllerReverseNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'controllerReverseReason',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.controllerReverseReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'hcpAbortedNotes',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.hcpAbortedNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'hcpAbortedReason',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.hcpAbortedReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'hcpReverseNotes',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.hcpReverseNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'hcpReverseReason',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.hcpReverseReason ?? null;
        },
        onUpdateOnly: true,
    },

    {
        key: 'staffRequired',
        followUpCopy: [JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return !values.staffRequired ? 1 : values.staffRequired;
        },
    },
    {
        key: 'odsCode',
        followUpCopy: [JobType.ADMIN, JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return values.odsCode ? values.odsCode : null;
        },
    },
];
