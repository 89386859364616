import * as Yup from 'yup';

import { ValidationMessages, validationRegexes } from '../common';

export const AddressRequiredValidation = Yup.object({
    addressLine1: Yup.string().required(ValidationMessages.REQUIRED),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    town: Yup.string().required(ValidationMessages.REQUIRED),
    postCode: Yup.string()
        .required(ValidationMessages.REQUIRED)
        .matches(validationRegexes.postCode, ValidationMessages.POSTCODE_INVALID),
});

export const AddressOptionalValidation = Yup.object({
    addressLine1: Yup.string(),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    town: Yup.string(),
    postCode: Yup.string().matches(validationRegexes.postCode, {
        message: ValidationMessages.POSTCODE_INVALID,
        excludeEmptyString: true,
    }),
});
