import { LiveLocationThreshold } from '@doc-abode/data-models';
import { ColorsForJobStatus } from '@doc-abode/helpers';
import { FC } from 'react';

import { ViewToShow } from '../../../../../constants/mainConst';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Markers } from '../mapView/Markers';
import { ClusterColor } from '../mapView/useMapContainerViewModel';

interface IMapMarkerLegendProps {
    markerType: 'hcp' | 'job' | 'cluster' | 'base';
    fill: string;
    label: string;
    description: string;
}

const MapMarkerLegend: FC<IMapMarkerLegendProps> = ({ markerType, fill, label, description }) => {
    let marker;

    switch (markerType) {
        case 'base':
            marker = Markers.base();
            break;
        case 'hcp':
            marker = Markers.singleHcp({
                fill,
                label,
            });
            break;
        case 'job':
            marker = Markers.single({
                fill,
                label,
                foundHcp: false,
            });
            break;
        case 'cluster':
            marker = Markers.cluster({
                fill,
                label,
                foundHcp: false,
            });
            break;
    }

    return (
        <>
            <div
                dangerouslySetInnerHTML={{ __html: marker }}
                className="ucr__legend-panel-icon-map"
            />
            <p className="ucr__legend-panel-label-map">{description}</p>
        </>
    );
};

export const LegendMap = () => {
    const {
        RootStore: {
            configStore: { liveLocationThreshold },
            ucrStore: { viewToShow },
        },
    } = useStores<{ RootStore: RootStore }>();

    const locationDataConfig = liveLocationThreshold as LiveLocationThreshold;

    return (
        <ConditionalDisplay show={viewToShow === ViewToShow.MAP}>
            <h4 className="ucr__legend-panel-titles">Map icons</h4>
            <div className="ucr__legend-panel-sub-section">
                <MapMarkerLegend
                    markerType="base"
                    fill="notNeeded"
                    label="notNeeded"
                    description="Location of base"
                />
            </div>
            <div className="ucr__legend-panel-sub-section">
                <MapMarkerLegend
                    markerType="hcp"
                    fill={`#${locationDataConfig?.color?.below ?? '834277'}`}
                    label="AB"
                    description={`HCP location <= ${locationDataConfig?.value} mins ago`}
                />
            </div>
            <div className="ucr__legend-panel-sub-section">
                <MapMarkerLegend
                    markerType="hcp"
                    fill={`#${locationDataConfig?.color?.above ?? 'dda0dd'}`}
                    label="AB"
                    description={`HCP location > ${locationDataConfig?.value} mins ago`}
                />
            </div>
            <div className="ucr__legend-panel-sub-section">
                <MapMarkerLegend
                    markerType="job"
                    fill={ColorsForJobStatus.COMPLETED}
                    label="AB"
                    description="Visit location: colour = status, initials = HCP"
                />
            </div>
            <div className="ucr__legend-panel-sub-section">
                <MapMarkerLegend
                    markerType="cluster"
                    fill={ClusterColor}
                    label="4"
                    description="Multiple pins in close proximity"
                />
            </div>
        </ConditionalDisplay>
    );
};
