import { isPathwayEnabled, isServiceEnabled } from '@doc-abode/helpers';
import moment from 'moment';

import { FormFieldRecord } from '../jobCreator';
import { JobType } from './JobTypes';

export const visitGenericSchema: FormFieldRecord[] = [
    { key: 'postVisitNotesBuddy', followUpCopy: [], onUpdateOnly: true },
    {
        key: 'availableFrom',
        followUpCopy: [],
    },
    {
        key: 'availableFrom',
        followUpCopy: [],
    },
    {
        key: 'earliestDateOfVisit',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            if (values.earliestDateOfVisit) {
                return moment(values.earliestDateOfVisit).clone().startOf('second').toISOString();
            }
            return null;
        },
    },
    {
        key: 'latestDateOfVisit',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            if (values.latestDateOfVisit) {
                return moment(values.latestDateOfVisit).clone().startOf('second').toISOString();
            }
            return null;
        },
    },
    {
        key: 'referralPathway',
        followUpCopy: [JobType.VISIT],
    },
    {
        key: 'referralPathway',
        followUpCopy: [JobType.VISIT],
        onCreateOnly: true,
        transformValue: (props) => {
            const { values, followUp, pathways } = props;
            if (!followUp) {
                return values.referralPathway;
            }

            const pathway = values.referralPathway;
            const { enabled } = isPathwayEnabled({
                pathways,
                selectedPathway: pathway,
            });

            if (!enabled) {
                return;
            }

            return pathway;
        },
    },
    {
        key: 'disposition',
        followUpCopy: [JobType.VISIT],
    },
    {
        key: 'disposition',
        followUpCopy: [JobType.VISIT],
        onCreateOnly: true,
        transformValue: (props) => {
            const { values, followUp, pathways } = props;

            // if its not a follow up, return existing set disposition
            if (!followUp) {
                return values.disposition;
            }

            // Follow up, disposition should be set to 'followUp' if it exists + enabled or left blank for user to choose.
            const disposition = 'followUp'; // Not sure if this should be an enum of the possible types of activityTypes
            const { enabled: pathwayEnabled, config: pathwayConfig } = isPathwayEnabled({
                pathways,
                selectedPathway: values.referralPathway,
            });

            if (!pathwayEnabled || !pathwayConfig?.services) {
                return;
            }

            const { enabled: serviceEnabled } = isServiceEnabled({
                services: pathwayConfig.services,
                selectedService: disposition,
            });

            if (!serviceEnabled) {
                return;
            }

            return disposition;
        },
    },
    {
        key: 'careComplexity',
        followUpCopy: [JobType.VISIT],
    },
    {
        key: 'carRequired',
        followUpCopy: [JobType.VISIT],
        transformValue: (props) => {
            const { values } = props;
            return !!values.carRequired;
        },
    },
    {
        key: 'jobStatusBeforeAbort',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.jobStatusBeforeAbort ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyJobStatusBeforeAbort',
        followUpCopy: [],
        transformValue: (props) => {
            const { values } = props;
            return values.buddyJobStatusBeforeAbort ?? null;
        },
        onUpdateOnly: true,
    },
];
