import * as Yup from 'yup';

import { AddressOptionalValidation, AddressRequiredValidation } from '../Address/AddressValidation';
import { FormSteps, patientDetailsOptionalSchema, patientDetailsRequiredSchema } from '../common';
import { jobStatusValidationSchema } from '../common/validationJobStatusTimes';

const activityDetails = Yup.object({
    activityType: Yup.string().required('Activity is required'),
    hcpId: Yup.string().required('Staff is required'),
    visitDate: Yup.date().typeError('Date is required'),
    startTime: Yup.date().typeError('Start time is required').required('Start time is required'),
    duration: Yup.string()
        .typeError('Duration is required')
        .required('Duration is required')
        .not(['00:00'], 'Duration cannot be 0 minutes'),
    notes: Yup.string(),
});

export const getStepSchema = (step: FormSteps, { patientFieldsRequired = false }) => {
    if (step === FormSteps.PATIENT) {
        return patientFieldsRequired
            ? patientDetailsRequiredSchema.concat(AddressRequiredValidation)
            : patientDetailsOptionalSchema.concat(AddressOptionalValidation);
    } else {
        return activityDetails.concat(jobStatusValidationSchema);
    }
};

export const getValidationSchema = ({ patientFieldsRequired = false }) => {
    const schema = patientFieldsRequired
        ? patientDetailsRequiredSchema.concat(AddressRequiredValidation).concat(activityDetails)
        : patientDetailsOptionalSchema.concat(AddressOptionalValidation).concat(activityDetails);

    return schema.concat(jobStatusValidationSchema);
};
