import {
    AddressWarningIcon,
    EarliestArrival,
    IconCallback,
    IconCarRequired,
    IconComplexCare,
    IconNonEnglishSpeaker,
    IconNote,
    IconWarning,
    LatestArrival,
    People,
} from '../../../../../helpers';

export const LegendJobIcons = () => {
    return (
        <>
            <h4 className="ucr__legend-panel-titles">Additional information</h4>
            <div className="ucr__legend-panel-sub-section">
                <IconWarning className="ucr__calendar-icon" />
                <p className="ucr__legend-panel-label">Job has warnings</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <AddressWarningIcon className="ucr__calendar-icon" />
                <p className="ucr__legend-panel-label">Address details could not be verified</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <EarliestArrival className="ucr__calendar-icon" />
                <p className="ucr__legend-panel-label">Earliest time of arrival set</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <LatestArrival className="ucr__calendar-icon" />
                <p className="ucr__legend-panel-label">Latest time of arrival set</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <People className="ucr__calendar-icon" />
                <p className="ucr__legend-panel-label">Double-up job</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <IconCarRequired className="ucr__calendar-icon" />
                <p className="ucr__legend-panel-label">Job requires a car</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <IconNote className="ucr__calendar-icon" />
                <p className="ucr__legend-panel-label">Pre-job notes set</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <IconNonEnglishSpeaker className="ucr__calendar-icon" />
                <p className="ucr__legend-panel-label">Patient is a non-English speaker</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <IconComplexCare className="ucr__calendar-icon" />
                <p className="ucr__legend-panel-label">Complex case</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <IconCallback className="ucr__calendar-icon" />
                <p className="ucr__legend-panel-label">Callback requested</p>
            </div>
        </>
    );
};
