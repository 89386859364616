import { Patient, PatientAlert, StaffAlert } from '@doc-abode/data-models';
import { isMultiAssigneeJob } from '@doc-abode/helpers';

import useStores from '../../../../hook/useStores';
import RootStore from '../../../../stores/RootStore';
import {
    getIsUser1,
    jobStatusCanBeChange,
} from '../forms/ChangeVisitStatus/changeVisitStatusHelpers';

export function usePatientFlagsToChangeStatus(
    patient: Patient | undefined,
    needPatientAlertsToFilterBefore = false,
) {
    const {
        RootStore: {
            ucrStore: {
                focusedUser,
                focusedJobId,
                patientAlerts: allPatientAlerts,
                staffAlerts: allStaffAlerts,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const patientAlerts = needPatientAlertsToFilterBefore
        ? allPatientAlerts.filter((alert: any) => alert.jobId === focusedJobId)
        : allPatientAlerts;

    // TODO: add filter Staff Alerts functionality if needed
    const staffAlerts = allStaffAlerts;

    let canHcp1ChangeStatus = false;
    if (patient?.jobStatus) {
        canHcp1ChangeStatus = jobStatusCanBeChange({ jobStatus: patient.jobStatus });
    }

    let canHcp2ChangeStatus = false;
    if (patient?.buddyJobStatus) {
        canHcp2ChangeStatus = jobStatusCanBeChange({ jobStatus: patient.buddyJobStatus });
    }

    const isStatusCanBeChange = canHcp1ChangeStatus || canHcp2ChangeStatus;

    const isNoPatientAlerts = patientAlerts.some(
        (alert: PatientAlert) => alert.jobId === focusedJobId && !alert.resolvedBy,
    );

    const isNoStaffAlerts = staffAlerts.some(
        (alert: StaffAlert) => alert.userId === patient?.hcpId && !alert.resolvedBy,
    );
    const isNoBuddyStaffAlerts = staffAlerts.some(
        (alert: StaffAlert) => alert.userId === patient?.buddyId && !alert.resolvedBy,
    );

    const isDoubleUp = isMultiAssigneeJob(patient);
    const isFirstUser = getIsUser1({ isDoubleUp, focusedUser });

    const isDisabled =
        (isFirstUser && isStatusCanBeChange && !isNoPatientAlerts && !isNoStaffAlerts) ||
        (!isFirstUser && isStatusCanBeChange && !isNoPatientAlerts && !isNoBuddyStaffAlerts);

    return {
        isStatusCanBeChange,
        isNoPatientAlerts,
        isNoStaffAlerts,
        isNoBuddyStaffAlerts,
        isDoubleUp,
        isFirstUser,
        isDisabled,
    };
}
