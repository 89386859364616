import { FC } from 'react';

import { Panel } from '../side';
import { LegendJobColours } from './LegendJobColours';
import { LegendJobIcons } from './LegendJobIcons';
import { LegendJobStates } from './LegendJobStates';
import { LegendMap } from './LegendMap';
import { LegendWarnings } from './LegendWarnings';

interface IProps {
    isShow: boolean;
    closePanel: () => void;
}

const Legend: FC<IProps> = ({ isShow, closePanel }) => {
    return (
        <Panel
            title="Legend"
            side="right"
            isShow={isShow}
            closePanel={closePanel}
            testId="legendPanel"
        >
            <div className="ucr__side-panel-block ucr__side-panel-block--scrollable">
                <div className="ucr__side-panel-legend-wrapper">
                    <LegendMap />
                    <LegendWarnings />
                    <LegendJobColours />
                    <LegendJobStates />
                    <LegendJobIcons />
                </div>
            </div>
        </Panel>
    );
};

export default Legend;
