import { FormikContextType, FormikValues, useFormikContext } from 'formik';

import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { Place } from '../../../../v2/form';

export const useAddressSelectViewModel = () => {
    const {
        RootStore: {
            configStore: {
                clientKeys: { googleMaps: googleMapsAPIKey },
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { setFieldValue }: FormikContextType<FormikValues> = useFormikContext();

    const onPlaceSelected = ({
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        postcode,
    }: Place) => {
        setFieldValue('addressLine1', addressLine1 ?? '');
        setFieldValue('town', city ?? '');
        setFieldValue('postCode', postcode ?? '');
        setFieldValue('addressLine2', addressLine2 ?? '');
        setFieldValue('addressLine3', addressLine3 ?? '');
    };

    return { onPlaceSelected, googleMapsAPIKey };
};
