import { AutocompletePlacesInput } from '../../../../v2/form';
import TextInput from '../../../../v2/form/TextInput/TextInput';
import { useAddressSelectViewModel } from './useAddressSelectViewModel';

interface AddressSelectProps {
    required: boolean;
    loading: boolean;
    isContinueBtnClicked: boolean;
}

export const AddressSelect = (props: AddressSelectProps) => {
    const { googleMapsAPIKey, onPlaceSelected } = useAddressSelectViewModel();
    const { required, loading, isContinueBtnClicked } = props;

    return (
        <>
            {googleMapsAPIKey ? (
                <AutocompletePlacesInput
                    name="addressLine1"
                    aria-label="Aaddress line 1"
                    className="v2__form-group--pos-1-2"
                    label="Address line 1"
                    required={required}
                    disabled={loading}
                    isContinueBtnClicked={isContinueBtnClicked}
                    onPlaceSelected={onPlaceSelected}
                    apiKey={googleMapsAPIKey}
                />
            ) : (
                <TextInput
                    name="addressLine1"
                    className="v2__form-group--pos-1-2"
                    label="Address line 1"
                    disabled={loading}
                />
            )}
            <TextInput
                name="addressLine2"
                className="v2__form-group--pos-2-2"
                label="Address line 2"
                disabled={loading}
            />
            <TextInput
                name="addressLine3"
                className="v2__form-group--pos-1-2"
                label="Address line 3"
                disabled={loading}
            />
            <TextInput
                name="town"
                aria-label="town"
                className="v2__form-group--pos-2-2"
                label="Town/City"
                required={required}
                disabled={loading}
                isContinueBtnClicked={isContinueBtnClicked}
            />
            <TextInput
                name="postCode"
                aria-label="postcode"
                className="v2__form-group--pos-1-2"
                label="Postcode"
                required={required}
                disabled={loading}
                isContinueBtnClicked={isContinueBtnClicked}
            />
        </>
    );
};
