import { Patient, Warning } from '@doc-abode/data-models';
import sortBy from 'lodash/sortBy';
import { FC } from 'react';

import filterPatientAlerts from '../../../../../../helpers/ucr/filterPatientAlerts';
import getHcpName from '../../../../../../helpers/ucr/getHcpName';
import {
    Activity,
    EarliestArrival,
    Event,
    Forward,
    Hand,
    Home,
    IconCalendar,
    IconCarRequired,
    IconComplexCare,
    IconNonEnglishSpeaker,
    LatestArrival,
    Pathway,
    Person,
    Sex,
    Sms,
} from '../../../../../../helpers/ucr/icons';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { formatDisplayDateTime } from '../../../../../modules/helpers/formatData';
import { DataDetails, IDetailsRow } from '../../../../../v2/components/DataDetails';
import { WarningBanner } from '../../../../../v2/form/WarningBanner';
import { useWarningConditions } from '../../../hooks/useWarningConditions';
import { useWarningMessageViewModel } from '../../../hooks/useWarningMessageViewModel';
import { IHeader, JobSummaryHCP, JobSummaryHeader } from './JobSummaryHeader';

export enum JobType {
    ADMIN = 'admin',
    VISIT = 'visit',
}

interface IJobSummaryData {
    // Patient Details
    address?: string;
    dateOfBirth?: string | null;
    gender?: string;

    // Activity & Activity Details
    abortedReason?: string;
    abortedNotes?: string;
    referrer?: string;
    pathway?: string;
    service?: string;
    activity?: string;

    // Notes
    preVisitNotes?: string;

    // Warning Icons
    isNonEnglishSpoken?: boolean;
    isCareComplex?: boolean;
    isCarRequired?: boolean;
    earliestDateOfVisit?: string;
    latestDateOfVisit?: string;
}

export interface IJobSummaryComponentProps {
    type: JobType;

    header: IHeader;

    data: IJobSummaryData;

    patient: Patient;
}

const patientDetailsRows: IDetailsRow<IJobSummaryData>[] = [
    { key: 'address', Icon: Home },
    { key: 'dateOfBirth', Icon: IconCalendar },
    { key: 'gender', Icon: Sex },
];
const visitDetailsRows: IDetailsRow<IJobSummaryData>[] = [
    { key: 'referrer', Icon: Forward },
    { key: 'pathway', Icon: Pathway },
    { key: 'service', Icon: Hand },
];
const activityDetailsRows: IDetailsRow<IJobSummaryData>[] = [
    { key: 'abortedReason', Icon: Person },
    { key: 'abortedNotes', Icon: Person },
    { key: 'activity', Icon: Activity },
];
const preVisitDetailsRows: IDetailsRow<IJobSummaryData>[] = [{ key: 'preVisitNotes', Icon: Sms }];
const postVisitDetailsRows: IDetailsRow<IJobSummaryData>[] = [
    { key: 'abortedReason', Icon: Event, urgent: true },
    { key: 'abortedNotes', Icon: Sms, urgent: true },
];

type IGetWarningsRows = (
    isVisitEarliestTimeBreach: boolean,
    isVisitLatestTimeBreach: boolean,
    keyArray: string[],
    iconsArray: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>[],
) => IDetailsRow<Record<string | number | symbol, unknown>>[];

const getWarningsRows: IGetWarningsRows = (
    isVisitEarliestTimeBreach,
    isVisitLatestTimeBreach,
    keyArray,
    iconsArray,
) => [
    { key: 'earliestDateOfVisit', Icon: EarliestArrival, urgent: isVisitEarliestTimeBreach },
    { key: 'latestDateOfVisit', Icon: LatestArrival, urgent: isVisitLatestTimeBreach },
    {
        multipleKey: keyArray,
        multipleIcon: iconsArray,
    },
];

export const JobSummaryComponent: FC<IJobSummaryComponentProps> = ({
    type,
    header,
    data,
    patient,
}) => {
    const {
        RootStore: {
            usersStore: { users },
            ucrStore: { patientAlerts: allPatientAlerts },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { hasEarliestTimeBreach, hasLatestTimeBreach } = useWarningConditions({ job: patient });
    const { warningMessages, hasWarningMessages } = useWarningMessageViewModel({ job: patient });

    const sortedHcp = header.hcps?.length
        ? sortBy(header.hcps, (hcp) => hcp?.hcpID !== header.currentHcp)
        : null;

    const filteredData = [];
    const filteredIcons = [];

    for (const [key, value] of Object.entries(data)) {
        if (key === 'isNonEnglishSpoken' && value) {
            filteredData.push(key);
            filteredIcons.push(IconNonEnglishSpeaker);
        } else if (key === 'isCarRequired' && value) {
            filteredData.push(key);
            filteredIcons.push(IconCarRequired);
        } else if (key === 'isCareComplex' && value) {
            filteredData.push(key);
            filteredIcons.push(IconComplexCare);
        }
    }

    const warningsRows = getWarningsRows(
        hasEarliestTimeBreach,
        hasLatestTimeBreach,
        filteredData,
        filteredIcons,
    );

    const { unresolved } = filterPatientAlerts(allPatientAlerts, patient.id);

    const unresolvedAlerts: Warning[] = [];
    unresolved.forEach((patientAlert) => {
        const message = `Unresolved patient alert raised by ${getHcpName(
            users,
            patientAlert.createdBy,
        )} at ${formatDisplayDateTime(patientAlert.createdAt)}: ${patientAlert.type}`;

        unresolvedAlerts.push({ category: patientAlert.category, message });
    });

    return (
        <div className={`job-summary job-summary--${type}`}>
            <JobSummaryHeader header={header} patient={patient} />

            <ConditionalDisplay show={unresolvedAlerts.length > 0}>
                <div className="job-summary__section job-summary__section--borderless">
                    <WarningBanner warnings={unresolvedAlerts} intent="danger" compact={true} />
                </div>
            </ConditionalDisplay>

            <ConditionalDisplay show={hasWarningMessages}>
                <div className="job-summary__section job-summary__section--borderless">
                    <WarningBanner warnings={warningMessages} intent="warning" compact={true} />
                </div>
            </ConditionalDisplay>

            {sortedHcp && (
                <div>
                    {sortedHcp.map((hcp) => (
                        <JobSummaryHCP key={hcp?.hcpID} hcp={hcp} currentHcp={header.currentHcp} />
                    ))}
                </div>
            )}
            <DataDetails
                rows={patientDetailsRows}
                data={data}
                classes="job-summary__section job-summary__section--solid"
            />
            {type === JobType.VISIT && (
                <DataDetails rows={visitDetailsRows} data={data} classes="job-summary__section" />
            )}

            {type === JobType.ADMIN && (
                <DataDetails
                    rows={activityDetailsRows}
                    data={data}
                    classes="job-summary__section"
                    isAborted={!!data.abortedReason}
                />
            )}

            <DataDetails rows={warningsRows} data={data} classes="job-summary__section " />

            <DataDetails rows={preVisitDetailsRows} data={data} classes="job-summary__section" />
            <DataDetails
                rows={postVisitDetailsRows}
                data={data}
                classes="job-summary__section job-summary__section--no-border"
            />
        </div>
    );
};
