import { useMutation } from '@apollo/client';
import { JobStatus } from '@doc-abode/data-models';
import { isAborted, isMultiAssigneeJob } from '@doc-abode/helpers';
import { Formik, FormikValues } from 'formik';
import { FC, useContext } from 'react';

import { UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import useStores from '../../../../../hook/useStores';
import { JobsContext } from '../../../../../providers';
import RootStore from '../../../../../stores/RootStore';
import { Dialogs } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import { useView } from '../../views/useView';
import { getIsUser1 } from '../ChangeVisitStatus/changeVisitStatusHelpers';
import ReverseJobForm from './ReverseJobForm';

interface IProps {
    onClose: () => void;
}

const ReverseVisit: FC<IProps> = ({ onClose }) => {
    const {
        RootStore: {
            ucrStore: { focusedUser, reverseVisit, setReverseVisit, setOpenedDialog },
            userStore: {
                user: { username },
            },
            lovsStore: { controllerReverseReason },
        },
    } = useStores<{ RootStore: RootStore }>();

    const jobsContext = useContext(JobsContext);

    const { currentViewState } = useView();

    const [updateJob, { loading }] = useMutation(UPDATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                jobsContext.setRefreshAssignedJobs(true);
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });

    if (!reverseVisit) return <></>;

    const isDoubleUp = isMultiAssigneeJob(reverseVisit);

    const isFirstUser = getIsUser1({ isDoubleUp, focusedUser });

    const isDisabled =
        (isFirstUser && isAborted({ jobStatus: reverseVisit.jobStatus })) ||
        (!isFirstUser && isAborted({ jobStatus: reverseVisit.buddyJobStatus }));

    const onSubmit = async (values: FormikValues) => {
        if (
            !isDisabled ||
            (isDoubleUp && values.reverseForOption === '') ||
            values.controllerReverseReason === ''
        ) {
            return;
        }

        const isUser1 = !isDoubleUp || (isDoubleUp && values.reverseForOption !== 'user2');
        const isUser2 = isDoubleUp && values.reverseForOption !== 'user1';

        const input: any = {
            controllerReverseReason: isUser1
                ? values.controllerReverseReason.value
                : reverseVisit.controllerReverseReason,
            controllerReverseNotes: isUser1
                ? values.controllerReverseNotes
                : reverseVisit.controllerReverseNotes,
            buddyControllerReverseReason: isUser2
                ? values.controllerReverseReason
                : reverseVisit.buddyControllerReverseReason,
            buddyControllerReverseNotes: isUser2
                ? values.controllerReverseNotes
                : reverseVisit.buddyControllerReverseNotes,
            finishedDateTime: isUser1 ? null : reverseVisit.finishedDateTime,
            buddyFinishedDateTime: isUser2 ? null : reverseVisit.buddyFinishedDateTime,
            id: reverseVisit.id,
            lastUpdatedBy: username,
            version: reverseVisit.version + 1,
        };

        if (isUser1) {
            if (reverseVisit?.jobStatusBeforeAbort) {
                input.jobStatus = reverseVisit.jobStatusBeforeAbort;
            } else if (reverseVisit.hcpId) {
                input.jobStatus = JobStatus.ACCEPTED;
            } else {
                input.jobStatus = JobStatus.PENDING;
            }
        }

        if (isUser2) {
            if (reverseVisit?.buddyJobStatusBeforeAbort) {
                input.buddyJobStatus = reverseVisit.buddyJobStatusBeforeAbort;
            } else if (reverseVisit.buddyId) {
                input.buddyJobStatus = JobStatus.ACCEPTED;
            } else {
                input.buddyJobStatus = JobStatus.PENDING;
            }
        }

        try {
            await updateJob({ variables: { input } });
            setReverseVisit(null);
            setOpenedDialog(Dialogs.NONE);
            AppToaster.show({
                message: 'Aborting reversed successfully',
                intent: 'success',
            });
        } catch (err) {
            console.error('Error reversing cancellation', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to reverse the cancellation',
                intent: 'danger',
            });
        }
    };

    return (
        <Formik
            initialValues={{
                reverseFor: !isDisabled && (focusedUser || ''),
                controllerReverseNotes: '',
                controllerReverseReason: controllerReverseReason[0],
                reverseForOption: focusedUser === 'user1' ? 'user1' : 'user2',
            }}
            onSubmit={onSubmit}
        >
            {reverseVisit && (
                <ReverseJobForm visit={reverseVisit} loading={loading} onClose={onClose} />
            )}
        </Formik>
    );
};

export default ReverseVisit;
