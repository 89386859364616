import { AdminTypeV2, Pathway, Patient, Service } from '@doc-abode/data-models';
import {
    getFriendlyAdminTypeLabel,
    getFriendlyPathwayLabel,
    getFriendlyServiceLabel,
    isAdminJob,
} from '@doc-abode/helpers';

export function getPathwayServiceOrAdminTypeLabels(
    job: Patient,
    pathways: Pathway[],
    adminTypes: AdminTypeV2[],
) {
    const isAdmin = isAdminJob(job);

    let pathwayDisplayLabel;
    let serviceOrAdminTypeDisplayLabel;
    let serviceOrAdminTypeLabel;
    let serviceOrAdminTypeName;

    if (isAdmin) {
        pathwayDisplayLabel = 'Admin';
        serviceOrAdminTypeDisplayLabel = getAdminDisplayName(adminTypes, job.activityType);
        serviceOrAdminTypeLabel = 'Activity';
        serviceOrAdminTypeName = 'activityType';
    } else {
        const pathway = pathways.find((pathway: any) => pathway.value === job?.referralPathway);

        pathwayDisplayLabel = getPathwayDisplayName(pathways, job?.referralPathway);
        serviceOrAdminTypeDisplayLabel = getServiceDisplayName(pathway?.services, job.disposition);
        serviceOrAdminTypeLabel = 'Service';
        serviceOrAdminTypeName = 'disposition';
    }

    return {
        pathwayDisplayLabel,
        serviceOrAdminTypeDisplayLabel,
        serviceOrAdminTypeLabel,
        serviceOrAdminTypeName,
    };
}

export const getPathwayDisplayName = (pathways: Pathway[] = [], value?: string): string => {
    // Default value if pathway cannot be resolved
    let name = 'Other';

    if (value) {
        // Use the display label from configuration where found
        // or fallback to uppercasing the value for defunct/non-migrated pathways
        name = getFriendlyPathwayLabel(value, pathways, value.toUpperCase());
    }

    return name;
};

export const getServiceDisplayName = (services: Service[] = [], value?: string): string => {
    let name = '';

    if (value) {
        name = getFriendlyServiceLabel(value, services, value.toUpperCase());
    }

    // We used to have hardcoded values for service names which are no longer supported
    return name;
};

export const getAdminDisplayName = (adminTypes: AdminTypeV2[] = [], value?: string): string => {
    let name = 'Admin';

    if (value) {
        name = getFriendlyAdminTypeLabel(value, adminTypes, value.toUpperCase());
    }

    return name;
};
