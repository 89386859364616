import { JobStatus } from '@doc-abode/data-models';
import { isArrivedOrCompleted } from '@doc-abode/helpers';

export interface IShouldShowPostJobNotes {
    jobStatus: JobStatus | undefined;
}

export function shouldShowPostJobNotes({ jobStatus }: IShouldShowPostJobNotes): boolean {
    if (jobStatus === undefined) {
        return false;
    }

    return isArrivedOrCompleted({ jobStatus });
}
