import { memo } from 'react';

import {
    EarliestArrival,
    IconNonEnglishSpeaker,
    IconS1Referral,
    IconWarning,
    LatestArrival,
    People,
} from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

export const LegendWarnings = memo(() => {
    const {
        RootStore: {
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    return (
        <>
            <h4 className="ucr__legend-panel-titles">Warning indicators</h4>
            <div className="ucr__legend-panel-sub-section">
                <IconWarning className="ucr__calendar-icon ucr__calendar-icon--alert" />
                <p className="ucr__legend-panel-label">Job has warnings</p>
            </div>
            {isFeatureEnabled('s1Enabled') && (
                <div className="ucr__legend-panel-sub-section">
                    <IconS1Referral className="ucr__calendar-icon ucr__calendar-icon--alert" />
                    <p className="ucr__legend-panel-label">Job is missing a S1 referral</p>
                </div>
            )}
            <div className="ucr__legend-panel-sub-section">
                <EarliestArrival className="ucr__calendar-icon ucr__calendar-icon--alert" />
                <p className="ucr__legend-panel-label">Earliest time of arrival breached</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <LatestArrival className="ucr__calendar-icon ucr__calendar-icon--alert" />
                <p className="ucr__legend-panel-label">Latest time of arrival breached</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <IconNonEnglishSpeaker className="ucr__calendar-icon ucr__calendar-icon--alert" />
                <p className="ucr__legend-panel-label">
                    Assignees do not speak the patient's language
                </p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <People className="ucr__calendar-icon ucr__calendar-icon--alert" />
                <p className="ucr__legend-panel-label">Job is missing assignees</p>
            </div>
        </>
    );
});
