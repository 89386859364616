export const LegendJobStates = () => {
    return (
        <>
            <h4 className="ucr__legend-panel-titles">Job states</h4>
            <div className="ucr__legend-panel-sub-section">
                <div className="job-status-background__pending ucr__legend-panel-square-box-size"></div>
                <p className="ucr__legend-panel-label">Job has not been assigned yet</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <div className="job-status-background__accepted ucr__legend-panel-square-box-size"></div>
                <p className="ucr__legend-panel-label">Job has not been started yet</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <div className="job-status-background__current ucr__legend-panel-square-box-size"></div>
                <p className="ucr__legend-panel-label">HCP is en route to the job</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <div className="job-status-background__arrived ucr__legend-panel-square-box-size"></div>
                <p className="ucr__legend-panel-label">HCP has arrived and is providing care</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <div className="job-status-background__completed ucr__legend-panel-square-box-size"></div>
                <p className="ucr__legend-panel-label">Job has been completed</p>
            </div>
            <div className="ucr__legend-panel-sub-section">
                <div className="job-status-background__aborted ucr__legend-panel-square-box-size"></div>
                <p className="ucr__legend-panel-label">Job has been aborted</p>
            </div>
        </>
    );
};
