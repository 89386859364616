import { JobStatus, Patient } from '@doc-abode/data-models';
import { getJobStatus, isAborted, isCompleted } from '@doc-abode/helpers';
import cn from 'classnames';
import slice from 'lodash/slice';
import moment from 'moment';
import { useMemo } from 'react';

import { getJobDuration } from '../../../../../../helpers/ucr/getDurationTime';
import {
    AddressWarningIcon,
    EarliestArrival,
    IconCarRequired,
    IconComplexCare,
    IconMore,
    IconNonEnglishSpeaker,
    IconNote,
    IconS1Referral,
    IconWarning,
    LatestArrival,
    People,
} from '../../../../../../helpers/ucr/icons';
import { useWarningConditions } from '../../../hooks/useWarningConditions';
import { EnumJobContainer } from './JobTypes';

interface Props {
    container: EnumJobContainer;
    job: Patient;
    isBuddy: boolean;
}

// Only certain states quality for warning conditions
function isJobInQualifyingStateForGeneralWarning(jobStatus: JobStatus) {
    return !isCompleted({ jobStatus }) && !isAborted({ jobStatus });
}

function getMaxIconLength(
    job: Patient,
    container: EnumJobContainer,
    currentMax: number,
    isBuddy: boolean,
): number {
    if (container === EnumJobContainer.CALENDAR && job.duration) {
        const durationInMinutes = getJobDuration(job, isBuddy);

        return Math.floor(durationInMinutes / 10) * 2;
    }

    return currentMax;
}

export const useWarningIconsViewModel = ({ container, job, isBuddy }: Props) => {
    const {
        hasAddressWarning,
        hasEarliestTime,
        hasLatestTime,
        hasEarliestTimeBreach,
        hasLatestTimeBreach,
        isDoubleUp,
        hasDoubleUpWarning,
        hasGeneralWarning,
        hasCarRequired,
        hasComplexCare,
        hasPreJobNotes,
        hasNonEnglishSpeaker,
        hasLanguageWarning,
        hasS1ReferralWarning,
    } = useWarningConditions({ job });

    return useMemo(() => {
        // Warnings can be suppressed under certain circumstances
        const jobStatus = getJobStatus(job, isBuddy);
        const isInQualifyState = isJobInQualifyingStateForGeneralWarning(jobStatus);

        // Determine the styling for the icons to show under normal conditions and if the relevant warning is in an elevated/warning state
        const iconBaseClassName =
            container === EnumJobContainer.LIST ? 'ucr-listview__icon' : 'ucr__calendar-icon';
        const iconAlertClassName = `${iconBaseClassName}--alert`;

        // List of all warning conditions leading to a
        const allWarningIcons = [
            {
                name: 'triangleWarning',
                isActive: hasGeneralWarning && isInQualifyState,
                tooltip: 'Job has warnings',
                render: () => (
                    <div data-testid="icon-triangleWarning">
                        <IconWarning className={`${iconBaseClassName} ${iconAlertClassName}`} />
                    </div>
                ),
            },
            {
                name: 's1ReferralWarning',
                isActive: hasS1ReferralWarning && container === EnumJobContainer.LIST,
                tooltip: 'Job is missing a S1 referral',
                render: () => (
                    <div data-testid="icon-s1ReferralWarning">
                        <IconS1Referral className={`${iconBaseClassName} ${iconAlertClassName}`} />
                    </div>
                ),
            },
            {
                name: 'doubleUp',
                isActive: isDoubleUp,
                tooltip: 'Double-up job',
                render: () => (
                    <div data-testid="icon-doubleUp">
                        <People
                            className={cn(iconBaseClassName, {
                                [`${iconAlertClassName}`]: hasDoubleUpWarning,
                            })}
                        />
                    </div>
                ),
            },
            {
                name: 'addressWarning',
                isActive: hasAddressWarning,
                tooltip: 'Address details could not be verified',
                render: () => (
                    <div data-testid="icon-addressWarning">
                        <AddressWarningIcon className={`${iconBaseClassName}`} />
                    </div>
                ),
            },
            {
                name: 'earliestTime',
                isActive: hasEarliestTime,
                tooltip: 'Earliest time of arrival set',
                render: () => {
                    if (container === EnumJobContainer.UNASSIGNED) {
                        return (
                            <div
                                data-testid="icon-earliestTime"
                                className={cn('ucr__calendar-time-wrapper', {
                                    'ucr__calendar-time-wrapper--alert': hasEarliestTimeBreach,
                                })}
                            >
                                <EarliestArrival
                                    className={cn(iconBaseClassName, {
                                        [`${iconAlertClassName}`]: hasEarliestTimeBreach,
                                    })}
                                />
                                <span
                                    className={cn('ucr__calendar-time-text', {
                                        'ucr__calendar-time-text--alert': hasEarliestTimeBreach,
                                    })}
                                >
                                    {moment(job.earliestDateOfVisit).format('HH:mm')}
                                </span>
                            </div>
                        );
                    }
                    return (
                        <div data-testid="icon-earliestTime">
                            <EarliestArrival
                                className={cn(iconBaseClassName, {
                                    [`${iconAlertClassName}`]: hasEarliestTimeBreach,
                                })}
                            />
                        </div>
                    );
                },
            },
            {
                name: 'latestTime',
                isActive: hasLatestTime,
                tooltip: 'Latest time of arrival set',
                render: () => {
                    if (container === EnumJobContainer.UNASSIGNED) {
                        return (
                            <div
                                data-testid="icon-latestTime"
                                className={cn('ucr__calendar-time-wrapper', {
                                    'ucr__calendar-time-wrapper--alert': hasLatestTimeBreach,
                                })}
                            >
                                <LatestArrival
                                    className={cn(iconBaseClassName, {
                                        [`${iconAlertClassName}`]: hasLatestTimeBreach,
                                    })}
                                />
                                <span
                                    className={cn('ucr__calendar-time-text', {
                                        'ucr__calendar-time-text--alert': hasLatestTimeBreach,
                                    })}
                                >
                                    {moment(job.latestDateOfVisit).format('HH:mm')}
                                </span>
                            </div>
                        );
                    }
                    return (
                        <div data-testid="icon-latestTime">
                            <LatestArrival
                                className={cn(iconBaseClassName, {
                                    [`${iconAlertClassName}`]: hasLatestTimeBreach,
                                })}
                            />
                        </div>
                    );
                },
            },
            {
                name: 'carRequired',
                isActive: hasCarRequired,
                tooltip: 'Job requires a car',
                render: () => (
                    <div data-testid="icon-carRequired">
                        <IconCarRequired className={iconBaseClassName} />
                    </div>
                ),
            },
            {
                name: 'complexCare',
                isActive: hasComplexCare,
                tooltip: 'Complex case',
                render: () => (
                    <div data-testid="icon-complexCare">
                        <IconComplexCare className={iconBaseClassName} />
                    </div>
                ),
            },
            {
                name: 'preJobNotes',
                isActive: hasPreJobNotes,
                tooltip: 'Pre-job notes set',
                render: () => (
                    <div data-testid="icon-preJobNotes">
                        <IconNote className={iconBaseClassName} />
                    </div>
                ),
            },
            {
                name: 'nonEnglishSpeaker',
                isActive: hasNonEnglishSpeaker,
                tooltip: 'Patient is a non-English speaker',
                render: () => (
                    <div data-testid="icon-nonEnglishSpeaker">
                        <IconNonEnglishSpeaker
                            className={cn(iconBaseClassName, {
                                [`${iconAlertClassName}`]: hasLanguageWarning,
                            })}
                        />
                    </div>
                ),
            },
        ];

        let filteredWarningIcons = allWarningIcons.filter(
            (warningCondition) => warningCondition.isActive,
        );

        // This code ensures we are only showing n icons according to the available space on the timeline
        let maxIconLength = getMaxIconLength(job, container, allWarningIcons.length, isBuddy);

        const shouldShowMoreIcon = filteredWarningIcons.length > maxIconLength;

        if (shouldShowMoreIcon) {
            // Truncate the array of warnings to the max length
            filteredWarningIcons = slice(filteredWarningIcons, 0, maxIconLength);

            // Substitute the last entry with the "More warnings" indicator
            filteredWarningIcons[filteredWarningIcons.length - 1] = {
                name: 'showMore',
                isActive: shouldShowMoreIcon,
                tooltip: 'Job has additional warning conditions',
                render: () => (
                    <div data-testid="icon-showMore">
                        <IconMore className={iconBaseClassName} />
                    </div>
                ),
            };
        }

        return {
            warningIcons: filteredWarningIcons,
        };
    }, [
        job,
        isBuddy,
        container,
        hasGeneralWarning,
        hasS1ReferralWarning,
        isDoubleUp,
        hasAddressWarning,
        hasEarliestTime,
        hasLatestTime,
        hasCarRequired,
        hasComplexCare,
        hasPreJobNotes,
        hasNonEnglishSpeaker,
        hasDoubleUpWarning,
        hasEarliestTimeBreach,
        hasLatestTimeBreach,
        hasLanguageWarning,
    ]);
};
