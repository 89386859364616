import * as Yup from 'yup';

import { AddressRequiredValidation } from '../Address/AddressValidation';
import { FormSteps, patientDetailsRequiredSchema, ValidationMessages } from '../common';
import { jobStatusValidationSchema } from '../common/validationJobStatusTimes';

const referralDetails = Yup.object({
    systmOneRef: Yup.string().nullable(),
    referralDateTime: Yup.date().nullable(),
    referralTime: Yup.date(),
});

const careDetails = Yup.object({
    referralPathway: Yup.string().required(ValidationMessages.REQUIRED),
    disposition: Yup.string().required(ValidationMessages.REQUIRED),
    careComplexity: Yup.string().nullable(),
    hcpId: Yup.string().nullable(),
    buddyId: Yup.string().nullable(),
    carRequired: Yup.boolean().nullable(),
    visitDate: Yup.date().required(ValidationMessages.REQUIRED).nullable().default(undefined),
    earliestDateOfVisit: Yup.date().nullable(),
    latestDateOfVisit: Yup.date().nullable(),
    startTime: Yup.date()
        .when('hcpId', (hcpId: string, schema: Yup.DateSchema) =>
            hcpId ? schema.required(ValidationMessages.STAFF_MEMBER_REQUIRED) : schema,
        )
        .nullable(),
    duration: Yup.string()
        .when('hcpId', (hcpId: string, schema: Yup.StringSchema) =>
            hcpId ? schema.required(ValidationMessages.DURATION_REQUIRED) : schema,
        )
        .not(['00:00'], ValidationMessages.DURATION_NOT_NULL)
        .nullable(),
    notes: Yup.string().nullable(),
});

const stepSchemas: { [key: string]: Yup.AnyObjectSchema } = {
    [FormSteps.PATIENT]: patientDetailsRequiredSchema.concat(AddressRequiredValidation),
    [FormSteps.REFERRAL]: referralDetails,
    [FormSteps.CARE]: careDetails.concat(jobStatusValidationSchema),
};

export const getStepSchema = (step: FormSteps) => stepSchemas[step];

const validationSchema = patientDetailsRequiredSchema
    .concat(AddressRequiredValidation)
    .concat(referralDetails)
    .concat(careDetails)
    .concat(jobStatusValidationSchema);

export default validationSchema;
