import * as Yup from 'yup';

export enum ValidationMessages {
    REQUIRED = 'This field is required.',
    STAFF_MEMBER_REQUIRED = 'Start time is required if a staff member is assigned',
    DURATION_REQUIRED = 'Duration is required if a staff member is assigned',
    DURATION_NOT_NULL = 'Duration cannot be 0 minutes',
    MADE_CURRENT_IN_FUTURE = 'The time the job was made current cannot be in the future.',
    MADE_CURRENT_VS_ARRIVAL = 'The time the job was made current cannot be later than the actual start time.',
    ARRIVED_IN_FUTURE = 'The arrival time cannot be in the future.',
    ARRIVED_VS_FINISHED = 'The actual start time cannot be later than the actual end time.',
    ARRIVED_VS_ABORTED = 'The actual start time cannot be later than the aborted time.',
    FINISHED_IN_FUTURE = 'The completion time cannot be in the future.',
    HCP_ABORTED_IN_FUTURE = 'The HCP aborted time cannot be in the future.',
    ARRIVED_AFTER_MADE_CURRENT = 'The actual start time cannot be earlier than the time the job was made current.',
    FINISHED_AFTER_MADE_CURRENT = 'The actual end time cannot be earlier than the time the job was made current.',
    ABORTED_AFTER_MADE_CURRENT = 'The HCP aborted time cannot be earlier than the time the job was made current.',
    FINISHED_AFTER_ARRIVED = 'The actual end time cannot be earlier than the actual start time.',
    ABORTED_AFTER_ARRIVED = 'The HCP aborted time cannot be earlier than the actual start time.',
    POSTCODE_INVALID = 'Postcode format is invalid',
}

export const validationRegexes: { [key: string]: RegExp } = {
    postCode: /^[a-zA-Z]{1,2}(\d{1,2}|\d[a-zA-Z])\s*\d[a-zA-Z]{2}$/,
};

export const patientDetailsOptionalSchema = Yup.object({
    nhsNumber: Yup.string()
        .nullable()
        .matches(/^\d{10}$/, {
            excludeEmptyString: true,
            message: 'NHS number is invalid, must be 10 digits only.',
        }),
    firstName: Yup.string()
        .nullable()
        .matches(/^.{2,50}$/, {
            excludeEmptyString: true,
            message: 'Please enter between 2 and 50 characters.',
        }),
    middleName: Yup.string()
        .nullable()
        .matches(/^.{1,50}$/, {
            message: 'Please enter between 1 and 50 characters.',
            excludeEmptyString: true,
        }),
    lastName: Yup.string()
        .nullable()
        .matches(/^.{2,50}$/, {
            excludeEmptyString: true,
            message: 'Please enter between 2 and 50 characters.',
        }),
    contactNumber: Yup.string()
        .nullable()
        .matches(/^44\d{9,10}$/, {
            message:
                'Invalid phone number; number must be 9 or 10 digits excluding the country code.',
            excludeEmptyString: true,
        })
        .when(
            'additionalContactNumbers',
            (additionalContactNumbers: Array<string>, schema: any) => {
                return schema
                    .test('contactNumber unique', 'Phone numbers must be unique.', (value: any) => {
                        const valuesNotUnique: any = {};
                        let valuesNotUniqueFound: boolean = true;
                        if (additionalContactNumbers && additionalContactNumbers.length > 0) {
                            additionalContactNumbers.forEach((val: string) => {
                                if (val === value && val !== undefined) {
                                    valuesNotUniqueFound = false;
                                }
                                if (!valuesNotUnique[val]) {
                                    valuesNotUnique[val] = true;
                                }
                            });
                        }

                        return valuesNotUniqueFound;
                    })
                    .test(
                        'contactNumber required',
                        'Phone number field is required',
                        (value: any) => {
                            const isAdditionalNumberFilled =
                                additionalContactNumbers &&
                                additionalContactNumbers.some(
                                    (el) => el !== '' && el !== undefined,
                                );
                            if (isAdditionalNumberFilled && (value === undefined || value === '')) {
                                return false;
                            }
                            return true;
                        },
                    );
            },
        ),
    additionalContactNumbers: Yup.array()
        .of(
            Yup.string()
                .test('required', 'Phone number field is required', function (value) {
                    const match = this.path.match(/^.*\[(\d*)\]/);
                    if (match) {
                        const [, indexString] = match;
                        const index = parseInt(indexString);
                        for (let i = index + 1; i < this.parent.length; i++) {
                            if (
                                this.parent[i]?.trim() !== '' &&
                                this.parent[i]?.trim() !== undefined &&
                                (value?.trim() === '' || value?.trim() === undefined)
                            ) {
                                return false;
                            }
                        }
                    }

                    return true;
                })
                .test(
                    'additional contact number required',
                    'Phone numbers must be unique.',
                    function () {
                        const noUndefineds = this.parent.filter(
                            (x: string | undefined) => x !== undefined && x !== '',
                        );
                        const noDuplicates = new Set(noUndefineds);
                        return noUndefineds.length === noDuplicates.size;
                    },
                )
                .matches(/^44\d{9,10}$/, {
                    message:
                        'Mobile phone number is invalid, must be (+)44 followed by 9 or 10 digits only.',
                    excludeEmptyString: true,
                }),
        )
        .nullable(),
    dateOfBirth: Yup.date().nullable(),
    gender: Yup.string().nullable(),
});

export const patientDetailsRequiredSchema = Yup.object({
    nhsNumber: Yup.string()
        .required('This field is required.')
        .matches(/^\d{10}$/, 'NHS number is invalid, must be 10 digits only.'),
    firstName: Yup.string()
        .required('This field is required.')
        .matches(/^.{2,50}$/, 'Please enter between 2 and 50 characters.'),
    middleName: Yup.string()
        .nullable()
        .matches(/^.{1,50}$/, {
            message: 'Please enter between 1 and 50 characters.',
            excludeEmptyString: true,
        }),
    lastName: Yup.string()
        .required('This field is required.')
        .matches(/^.{2,50}$/, 'Please enter between 2 and 50 characters.'),
    contactNumber: Yup.string()
        .nullable()
        .matches(/^44\d{9,10}$/, {
            message:
                'Invalid phone number; number must be 9 or 10 digits excluding the country code.',
            excludeEmptyString: true,
        })
        .when(
            'additionalContactNumbers',
            (additionalContactNumbers: Array<string>, schema: any) => {
                return schema
                    .test('contactNumber unique', 'Phone numbers must be unique.', (value: any) => {
                        const valuesNotUnique: any = {};
                        let valuesNotUniqueFound: boolean = true;
                        if (additionalContactNumbers && additionalContactNumbers.length > 0) {
                            additionalContactNumbers.forEach((val: string) => {
                                if (val === value && val !== undefined && val !== '') {
                                    valuesNotUniqueFound = false;
                                }
                                if (!valuesNotUnique[val]) {
                                    valuesNotUnique[val] = true;
                                }
                            });
                        }

                        return valuesNotUniqueFound;
                    })
                    .test(
                        'contactNumber required',
                        'Phone number field is required',
                        (value: any) => {
                            const isAdditionalNumberFilled =
                                additionalContactNumbers &&
                                additionalContactNumbers.some(
                                    (el) => el !== '' && el !== undefined,
                                );
                            if (isAdditionalNumberFilled && (value === undefined || value === '')) {
                                return false;
                            }
                            return true;
                        },
                    );
            },
        ),
    additionalContactNumbers: Yup.array()
        .of(
            Yup.string()
                .test('required', 'Phone number field is required', function (value) {
                    const match = this.path.match(/^.*\[(\d*)\]/);
                    if (match) {
                        const [, indexString] = match;
                        const index = parseInt(indexString);
                        for (let i = index + 1; i < this.parent.length; i++) {
                            if (
                                this.parent[i]?.trim() !== '' &&
                                this.parent[i]?.trim() !== undefined &&
                                (value?.trim() === '' || value?.trim() === undefined)
                            ) {
                                return false;
                            }
                        }
                    }

                    return true;
                })
                .test(
                    'additional contact number required',
                    'Phone numbers must be unique.',
                    function () {
                        const noUndefineds = this.parent.filter(
                            (x: string | undefined) => x !== undefined && x !== '',
                        );
                        const noDuplicates = new Set(noUndefineds);
                        return noUndefineds.length === noDuplicates.size;
                    },
                )
                .matches(/^44\d{9,10}$/, {
                    message:
                        'Mobile phone number is invalid, must be (+)44 followed by 9 or 10 digits only.',
                    excludeEmptyString: true,
                }),
        )
        .nullable(),
    dateOfBirth: Yup.date(),
    gender: Yup.string().required('This field is required.'),
    languagesSpoken: Yup.array().nullable(),
    staffPreferredGender: Yup.array().nullable(),
});
