import { Patient } from '@doc-abode/data-models';
import { isMultiAssigneeJob } from '@doc-abode/helpers';

export function isPartiallyAssignedJob(job: Patient): boolean {
    if (!isMultiAssigneeJob(job)) {
        // A job with single assignee can never be partially assigned
        return false;
    }

    // If the job is a multi-assignee job, it is deemed partially assigned if
    if (job.hcpId && !job.buddyId) {
        // HCP1 is assigned but HCP2 isn't
        return true;
    }

    if (!job.hcpId && job.buddyId) {
        // HCP1 isn't assigned but HCP2 is
        return true;
    }

    // If all HCPs are unassigned or all HCPs are assigned, the job is not partially assigned
    return false;
}
