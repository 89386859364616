import { Formik } from 'formik';
import { FC } from 'react';

import AddVisitForm from './AddVisitForm';
import ReviewForm from './ReviewForm';
import { useAddVisitViewModel } from './useAddVisitViewModel';
import validationSchema from './validation';

const AddVisit: FC = () => {
    const {
        initialData,
        formData,
        loading,
        error,
        isJobCreated,
        onSubmit,
        saveVisitData,
        onEditReviewForm,
    } = useAddVisitViewModel();

    return (
        <Formik initialValues={initialData} validationSchema={validationSchema} onSubmit={onSubmit}>
            {formData.values ? (
                <ReviewForm
                    values={formData.values}
                    formMode={formData.formMode}
                    onEdit={onEditReviewForm}
                    onSave={saveVisitData}
                    loading={loading}
                    isJobCreated={isJobCreated}
                />
            ) : (
                <AddVisitForm
                    step={formData.step}
                    loading={loading}
                    error={error}
                    formMode={formData.formMode}
                    onSubmit={onSubmit}
                />
            )}
        </Formik>
    );
};

export default AddVisit;
