import { Patient, S1JobSyncStatus } from '@doc-abode/data-models';
import { getJobS1SyncStatus, isMultiAssigneeJob, shouldBeSyncedToS1 } from '@doc-abode/helpers';

import { ViewToShow } from '../../../../constants/mainConst';
import useStores from '../../../../hook/useStores';
import RootStore from '../../../../stores/RootStore';

export const useJobS1SyncStatusViewModel = (job: Patient) => {
    const {
        RootStore: {
            configStore: { isFeatureEnabled, pathways, adminTypes, abortReasons },
            s1Store: { linkedVisits, linkedVisitsLoading },
        },
    } = useStores<{ RootStore: RootStore }>();

    const isS1SyncEnabled = isFeatureEnabled('s1Enabled');

    const showS1SyncStatus = isS1SyncEnabled && shouldBeSyncedToS1(job, pathways, adminTypes);
    const s1SyncStatusHcp1 = showS1SyncStatus
        ? getJobS1SyncStatus(job, linkedVisitsLoading, linkedVisits, abortReasons, false)
        : S1JobSyncStatus.DO_NOT_SYNC;
    const s1SyncStatusHcp2 =
        showS1SyncStatus && isMultiAssigneeJob(job)
            ? getJobS1SyncStatus(job, linkedVisitsLoading, linkedVisits, abortReasons, true)
            : S1JobSyncStatus.DO_NOT_SYNC;

    const getS1SyncStatusDisplayLabel = (isBuddy: boolean) => {
        return isBuddy ? s1SyncStatusHcp2 : s1SyncStatusHcp1;
    };

    const getS1SyncStatusClassname = (view: ViewToShow, isBuddy: boolean) => {
        const status = getS1SyncStatusDisplayLabel(isBuddy);
        const s1JobSyncStatusToLabelLookupTable = {
            [S1JobSyncStatus.LOADING]: 'loading',
            [S1JobSyncStatus.NOT_SYNCED]: 'not_synced',
            [S1JobSyncStatus.SYNCED]: 'synced',
            [S1JobSyncStatus.DO_NOT_SYNC]: 'na',
        };
        const label = s1JobSyncStatusToLabelLookupTable[status];

        const viewToClassLookupTable = {
            [ViewToShow.TIMELINE]: `ucr__calendar-s1-tag ucr__calendar-s1-tag--${label}`,
            [ViewToShow.VISITS_LIST]: `ucr-listview__s1-status ucr-listview__s1-status--${label}`,
            [ViewToShow.VISIT_DETAILS]: `ucr-visit-details__s1-status ucr-visit-details__s1-status--${label}`,
            [ViewToShow.JOB_SUMMARY_HEADER]: `ucr-job-summary-header__s1-status ucr-job-summary-header__s1-status--${label}`,
            [ViewToShow.MAP]: '',
            [ViewToShow.PATIENTS_LIST]: '',
            [ViewToShow.S1_MESSAGES]: '',
        };
        return viewToClassLookupTable[view];
    };

    const getS1ReferralStatusClass = () => {
        if (!job.systmOneRef) {
            return 'ucr__calendar-icon ucr__calendar-s1-tag ucr__calendar-s1-tag--referral-not-set';
        }

        return 'ucr__calendar-icon ucr__calendar-s1-tag ucr__calendar-s1-tag--referral-set';
    };

    return {
        isS1SyncEnabled,
        isLoading: linkedVisitsLoading,
        showS1SyncStatus,
        s1SyncStatusHcp1,
        s1SyncStatusHcp2,
        getS1SyncStatusDisplayLabel,
        getS1SyncStatusClassname,
        getS1ReferralStatusClass,
    };
};
