import { formatDisplayDate, formatDisplayTime } from '../../components/modules/helpers/formatData';

/**
 * Formats a datetime into output format DD MMM YYYY @ HH:mm
 * @param dateTime
 * @returns
 */
export const formatDateAtTime = (dateTime?: string | Date | null) => {
    if (!dateTime) {
        return undefined;
    }

    return `${formatDisplayDate(dateTime)} @ ${formatDisplayTime(dateTime)}`;
};
