import { DispositionType, JobStatus, Patient } from '@doc-abode/data-models';
import { getAbortedDetails, isMultiAssigneeJob } from '@doc-abode/helpers';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC } from 'react';

import { getDurationInMinutes, getStringEndTime } from '../../../../../../helpers';
import { formatDateAtTime } from '../../../../../../helpers/formatters/dateTimeFormatter';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { formatDisplayDate, formatDisplayTime } from '../../../../../modules/helpers/formatData';
import { Info, InfoItem } from '../../../../../v2/components';

type IProps = {
    job: Patient;
    isFirstUser: boolean;
};

export function formatPlannedTimes(job: Patient): string {
    let startLabel = '';
    let endLabel = '';
    let durationLabel = '';

    if (job.startDateTime) {
        startLabel = formatDateAtTime(job.startDateTime) ?? '';
    } else {
        startLabel = `${formatDisplayDate(job.dateOfVisit)}`;
    }

    if (job.duration) {
        // Whitespacing is deliberate
        durationLabel = ` (${getDurationInMinutes(job.duration)}mins)`;
    }

    if (job.startDateTime && job.duration) {
        // Whitespacing is deliberate
        endLabel = ` -> ${getStringEndTime(job.startDateTime, job.duration, 'HH:mm')}`;
    }

    // Lack of whitespacing is deliberate here, handled in individual labels to avoid conditional checks
    return `${startLabel}${endLabel}${durationLabel}`;
}

export function formatActualTimes(job: Patient, isBuddy: boolean): string {
    let startLabel = '';
    let endLabel = '';
    let durationLabel = '';

    let jobStatus: JobStatus | undefined = job.jobStatus;
    let arrivedDateTime = job.arrivedDateTime;
    let finishedDateTime = job.finishedDateTime;
    let hcpAbortedDateTime = job.hcpAbortedDateTime;

    if (isBuddy) {
        jobStatus = job.buddyJobStatus;
        arrivedDateTime = job.buddyArrivedDateTime;
        finishedDateTime = job.buddyFinishedDateTime;
        hcpAbortedDateTime = job.buddyHcpAbortedDateTime;
    }

    const completionTime =
        JobStatus.HCP_ABORTED === jobStatus ? hcpAbortedDateTime : finishedDateTime;

    if (arrivedDateTime) {
        startLabel = formatDateAtTime(arrivedDateTime) ?? '';
    }

    if (arrivedDateTime && completionTime) {
        // Whitespacing is deliberate
        endLabel = ` -> ${formatDisplayTime(completionTime)}`;
        durationLabel = ` (${moment(completionTime)
            .utc()
            .diff(moment(arrivedDateTime).utc(), 'minutes')}mins)`;
    }

    if (arrivedDateTime && !completionTime) {
        // Whitespacing is deliberate
        durationLabel = ' (ongoing)';
    }

    // Lack of whitespacing is deliberate here, handled in individual labels to avoid conditional checks
    return `${startLabel}${endLabel}${durationLabel}`;
}

const InfoPanelJobTimingsSection: FC<IProps> = ({ job, isFirstUser }) => {
    const { earliestDateOfVisit, latestDateOfVisit } = job;

    const isDoubleUp = isMultiAssigneeJob(job);
    const isAdmin = DispositionType.ADMIN === job.disposition;

    const abortedDetails = getAbortedDetails(job, !isFirstUser);
    const staffNumberSuffix = isFirstUser ? '#1' : '#2';

    return (
        <div className="visit-details__timing">
            <Info>
                <ConditionalDisplay show={!!abortedDetails}>
                    <InfoItem label="Aborted reason" intent="danger">
                        {abortedDetails?.abortedReason}
                    </InfoItem>
                    <InfoItem label="Aborted notes" intent="danger">
                        {abortedDetails?.abortedNotes}
                    </InfoItem>
                </ConditionalDisplay>
                <InfoItem label="Planned times" testId="infoPanel-plannedTimes">
                    {formatPlannedTimes(job)}
                </InfoItem>
                <InfoItem label={`Actual times ${isDoubleUp ? staffNumberSuffix : ''}`}>
                    {formatActualTimes(job, !isFirstUser || false)}
                </InfoItem>
                <ConditionalDisplay show={!isAdmin}>
                    <InfoItem label="Earliest time of visit">
                        {earliestDateOfVisit ? formatDateAtTime(job.earliestDateOfVisit) : ''}
                    </InfoItem>
                    <InfoItem label="Latest time of visit">
                        {latestDateOfVisit ? formatDateAtTime(job.latestDateOfVisit) : ''}
                    </InfoItem>
                </ConditionalDisplay>
            </Info>
        </div>
    );
};

export default observer(InfoPanelJobTimingsSection);
