import { Patient } from '@doc-abode/data-models';
// to prevent circular references we go direct.
import { isAborted, isDone } from '@doc-abode/helpers';
import { FormikValues } from 'formik';
import { FC } from 'react';

import { formatDateAtTime } from '../../../../../helpers/formatters/dateTimeFormatter';
import getHcpName from '../../../../../helpers/ucr/getHcpName';
import { transformFormikValuesToPatientForWarningValidation } from '../../../../../helpers/ucr/getWarnings';
import { IconAbort, IconAdd, IconChangeStatus, IconTick } from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts, Dialogs } from '../../../../../stores/UCRStore';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import {
    Accordion,
    AccordionColors,
    AccordionTab,
    Button,
    ButtonColors,
    ButtonElems,
    ButtonSizes,
} from '../../../../v2/components';
import { Text } from '../../../../v2/form';
import { WarningBanner } from '../../../../v2/form/WarningBanner';
import { usePatientFlagsToChangeStatus } from '../../hooks/usePatientFlagsToChangeStatus';
import { useWarningMessageViewModel } from '../../hooks/useWarningMessageViewModel';
import { FormMode, FormSteps, friendlyCareComplexity } from '../common';
import { DateTimeSelect } from '../DateTime';
import { PathwayServiceSelect } from '../Pathway';
import PatientDetailsReviewForm from '../PatientDetails/PatientDetailsReviewForm';
import { VisitData } from './AddVisitTypes';
import { JobStatusDetails } from './JobStatusDetails';
import { ReferralDetailsReview } from './ReferralDetailsReview';

interface Props {
    values: FormikValues;
    onSave?: () => Promise<void>;
    onEdit: (step: FormSteps) => void;
    patient?: Patient;
    formMode: string;
    loading?: boolean;
    isJobCreated: boolean;
}

interface careDetailsDoubleUpInterface {
    values: FormikValues;
    staffFiltered: string[];
    isSingleUp?: boolean;
}

interface statusFieldInterface {
    staffMember: string;
    memberName: string;
    isBuddy: boolean;
    values: FormikValues;
}

const ReviewForm: FC<Props> = ({
    values,
    onSave,
    onEdit,
    formMode,
    patient,
    loading,
    isJobCreated,
}) => {
    const {
        RootStore: {
            usersStore: { users },
            ucrStore: {
                openedDialogAlert,
                setOpenedDialogAlert,
                setOpenedDialog,
                setAbortVisit,
                setFollowUpVisitData,
                setFollowUpAdminTimeData,
                setLocalPatientData,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { isDoubleUp, isDisabled } = usePatientFlagsToChangeStatus(patient);
    const isSaveEnabled =
        formMode === FormMode.DEFAULT ||
        formMode === FormMode.ADD_VISIT ||
        formMode === FormMode.FOLLOW_UP;

    if (openedDialogAlert !== DialogAlerts.SAVE_VISIT && isSaveEnabled) {
        setOpenedDialogAlert(DialogAlerts.SAVE_VISIT);
    }

    // Wrapping in an array to keep changes to downstream dependencies minimal
    const staffFiltered = [
        getHcpName(users, values.hcpId, false),
        getHcpName(users, values.buddyId, false),
    ];

    const isHCPAborted = isAborted({ jobStatus: values?.jobStatus });
    const isBuddyAborted = isAborted({ jobStatus: values?.buddyJobStatus });

    const isJobAborted = isDoubleUp ? isHCPAborted && isBuddyAborted : isHCPAborted;

    const isHCPDone = isDone({ jobStatus: values?.jobStatus });
    const isBuddyDone = isDone({ jobStatus: values?.buddyJobStatus });

    const canShowAbortButton = isDoubleUp ? !isHCPDone || !isBuddyDone : !isHCPDone;

    const isAbortedMustShow =
        (isHCPAborted || isBuddyAborted) && (values.arrivedDateTime || values.buddyArrivedDateTime);
    const onCancelVisit = () => {
        if (patient) {
            setAbortVisit(patient);
        }
    };

    // Create a temporary job to allow reuse of validations
    const tmpJobForValidation = transformFormikValuesToPatientForWarningValidation({
        values,
        isAdmin: false,
    });

    const { warningMessages, hasWarningMessages } = useWarningMessageViewModel({
        job: tmpJobForValidation,
    });

    return (
        <Accordion>
            <AccordionTab
                name="patients"
                title="Patient details"
                onEdit={!isJobAborted ? () => onEdit(FormSteps.PATIENT) : undefined}
                color={AccordionColors.PINK}
                open
            >
                <PatientDetailsReviewForm values={values} />
            </AccordionTab>
            <AccordionTab
                name="referral"
                title="Referral details"
                onEdit={!isJobAborted ? () => onEdit(FormSteps.REFERRAL) : undefined}
                color={AccordionColors.PINK}
                open
            >
                <ReferralDetailsReview values={values} />
            </AccordionTab>
            <AccordionTab
                name="care"
                title="Care details"
                onEdit={
                    !isJobAborted || isAbortedMustShow ? () => onEdit(FormSteps.CARE) : undefined
                }
                color={AccordionColors.PINK}
                open
            >
                <CareDetails values={values} staffFiltered={staffFiltered} />
            </AccordionTab>
            <ConditionalDisplay show={hasWarningMessages}>
                <div className="v2__form-group--pos-1-1">
                    <WarningBanner spacerTop={true} compact={true} warnings={warningMessages} />
                </div>
            </ConditionalDisplay>
            <div className="v2__form-btn-section v2__form-btn-section--mb">
                {(formMode === FormMode.DEFAULT ||
                    formMode === FormMode.ADD_VISIT ||
                    formMode === FormMode.FOLLOW_UP) && (
                    <Button
                        className="v2__form-submit-button"
                        elem={ButtonElems.DIV}
                        name="Save visit"
                        Icon={IconTick}
                        size={ButtonSizes.MEDIUM}
                        disabled={loading}
                        clickEvent={() => {
                            if (onSave) onSave();
                            setLocalPatientData();
                        }}
                        testId="saveVisit"
                    />
                )}
                {patient?.jobStatus && (
                    <Button
                        className="v2__form-submit-button"
                        name="Create follow-up visit"
                        Icon={IconAdd}
                        clickEvent={() => {
                            const visitValues: VisitData = values as VisitData;
                            setFollowUpVisitData(visitValues);
                            setOpenedDialog(Dialogs.ADD_NEW_VISIT);
                        }}
                    />
                )}

                {patient?.jobStatus && (
                    <Button
                        className="v2__form-submit-button"
                        name="Create admin task"
                        Icon={IconAdd}
                        clickEvent={() => {
                            const visitValues: Partial<VisitData> = values;
                            setFollowUpAdminTimeData(visitValues);
                            setOpenedDialog(Dialogs.ADMINISTRATIVE_TIME);
                        }}
                    />
                )}
                {isDisabled ? (
                    <Button
                        className="v2__form-submit-button"
                        size={ButtonSizes.MEDIUM}
                        name="Change status"
                        Icon={IconChangeStatus}
                        clickEvent={() => setOpenedDialog(Dialogs.CHANGE_VISIT_STATUS)}
                    />
                ) : null}
                {canShowAbortButton && isJobCreated && formMode !== 'default' && (
                    <Button
                        className="v2__form-submit-button"
                        size={ButtonSizes.MEDIUM}
                        elem={ButtonElems.BUTTON}
                        color={ButtonColors.RED}
                        name="Abort job"
                        Icon={IconAbort}
                        clickEvent={onCancelVisit}
                    />
                )}
            </div>
        </Accordion>
    );
};

export default ReviewForm;

const StatusFields: FC<statusFieldInterface> = ({
    staffMember,
    memberName,
    isBuddy,
    values,
}): JSX.Element => {
    return (
        <>
            <Text
                name="staffMember"
                title={`Staff member ${staffMember}`}
                notSetText="Not yet recorded"
                description={memberName}
                className="v2__form-group--pos-1-1"
            />
            <JobStatusDetails values={values} isEditMode={false} isBuddy={isBuddy} />
        </>
    );
};

const CareDetailsDoubleUp: FC<careDetailsDoubleUpInterface> = ({
    values,
    staffFiltered,
    isSingleUp,
}): JSX.Element => {
    return (
        <>
            <div className="v2__form-block">
                <StatusFields
                    staffMember={'#1'}
                    memberName={staffFiltered[0]}
                    isBuddy={false}
                    values={values}
                />
            </div>
            <ConditionalDisplay show={!isSingleUp}>
                <div className="v2__form-block">
                    <StatusFields
                        staffMember={'#2'}
                        memberName={staffFiltered[1]}
                        isBuddy={true}
                        values={values}
                    />
                </div>
            </ConditionalDisplay>
        </>
    );
};

function CareDetails({ values, staffFiltered }: { values: FormikValues; staffFiltered: string[] }) {
    let formattedEarliestTime: string | undefined;
    let formattedLatestTime: string | undefined;

    if (values.earliestDateOfVisit) {
        formattedEarliestTime = formatDateAtTime(values.earliestDateOfVisit);
    }

    if (values.latestDateOfVisit) {
        formattedLatestTime = formatDateAtTime(values.latestDateOfVisit);
    }

    return (
        <>
            <div className="v2__form-block">
                <PathwayServiceSelect values={values} />
                <Text
                    name="notes"
                    title="Pre-job notes"
                    className="v2__form-group--pos-1-1 v2__form-group--notes"
                    description={values.notes}
                />
                <DateTimeSelect values={values} />
            </div>
            <CareDetailsDoubleUp
                isSingleUp={values.staffRequired !== 2}
                values={values}
                staffFiltered={staffFiltered}
            />
            <div className="v2__form-block">
                <Text
                    name="careComplexity"
                    title="Care complexity"
                    description={
                        values.careComplexity ? friendlyCareComplexity[values.careComplexity] : ''
                    }
                    className="v2__form-group--pos-1-4"
                />
                <Text
                    name="carRequired"
                    title="Car required"
                    description={values.carRequired ? 'Yes' : 'No'}
                    className="v2__form-group--pos-2-4"
                />
                <Text
                    name="earliestDateOfVisit"
                    title="Earliest date of visit"
                    description={formattedEarliestTime ?? ''}
                    className="v2__form-group--pos-3-4"
                />
                <Text
                    name="latestDateOfVisit"
                    title="Latest time of visit"
                    description={formattedLatestTime ?? ''}
                    className="v2__form-group--pos-4-4"
                />
            </div>
        </>
    );
}
