import { FormikErrors, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { dateTimeFormat } from '../../../../../constants/patientsConst';
import { getMomentDateFormatter } from '../../../../../helpers/ucr';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Button, ButtonSizes } from '../../../../v2/components';
import { Checkbox, DateInput, Select, TextArea } from '../../../../v2/form';
import { WarningBanner } from '../../../../v2/form/WarningBanner';
import { VisitValuesType } from '../../blocks/panels/VisitDetailsTypes';
import { HcpSelect } from '../../components';
import { FormMode, FormSteps, friendlyCareComplexity } from '../common';
import { DateTimeSelect } from '../DateTime';
import { PathwayServiceSelect } from '../Pathway';
import { JobStatusDetails } from './JobStatusDetails';
import { useCareDetailsViewModel } from './useCareDetailsViewModel';

export type CareDetailsType = {
    values: FormikValues;
    loading: boolean;
    formMode: FormMode;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    setValues: (
        values: React.SetStateAction<FormikValues>,
        shouldValidate?: boolean,
    ) => Promise<void | FormikErrors<FormikValues>>;
    onNextStep?: (nextStep: FormSteps, prevStep: FormSteps) => void;
    onSaveForm?: (curStep: FormSteps) => void;
};
export const CareDetails: FC<CareDetailsType> = ({
    values,
    loading,
    formMode,
    setFieldValue,
    setValues,
    onNextStep = () => {},
    onSaveForm = () => {},
}) => {
    const {
        MAXDATE,
        isContinueBtnClicked,
        MINTIME,
        highlightedWarnings,
        defaultStartTime,
        setStaffRequirement,
        setShowUnavailableBuddyHcp,
        setShowUnavailableHcp,
        showUnavailableBuddyHcp,
        showUnavailableHcp,
        buttonName,
        hasWarningMessages,
        onSubmit,
        warningMessages,
        isHcp1SelectorDisabled,
        isHcp2SelectorDisabled,
        isStaffRequiredSelectorDisabled,
        isVisitDateDisabled,
    } = useCareDetailsViewModel({
        values,
        loading,
        formMode,
        setFieldValue,
        setValues,
        onNextStep,
        onSaveForm,
    });

    const [timepickerKey, setTimepickerKey] = useState(uuidv4());

    return (
        <>
            <div className="v2__form-block">
                <PathwayServiceSelect
                    loading={loading}
                    values={values}
                    setValues={setValues}
                    isContinueBtnClicked={isContinueBtnClicked}
                    setTimepickerKey={setTimepickerKey}
                    isEditMode
                />
                <TextArea
                    name="notes"
                    className="v2__form-group--pos-1-1"
                    label="Pre-job notes"
                    disabled={loading}
                />
                <DateTimeSelect
                    values={values}
                    isEditMode
                    loading={loading}
                    isVisitDateDisabled={isVisitDateDisabled}
                    isContinueBtnClicked={isContinueBtnClicked}
                    maxDate={MAXDATE}
                    minTime={MINTIME}
                    timepickerKey={timepickerKey}
                    highlightedWarnings={highlightedWarnings}
                    defaultStartTime={values.startTime || defaultStartTime}
                    timeRequired={Boolean(values?.hcpId || values?.buddyId)}
                />
            </div>
            <div className="v2__form-block">
                <Select
                    name="staffRequired"
                    className="v2__form-group--pos-1-2"
                    label="Staffing requirements"
                    required
                    disabled={isStaffRequiredSelectorDisabled}
                    isContinueBtnClicked={isContinueBtnClicked}
                    onChange={(changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
                        const staffRequired = Number(changeEvent.target.value);
                        setFieldValue('staffRequired', staffRequired);
                        if (staffRequired === 1) {
                            setFieldValue('buddyId', '');
                            setFieldValue('buddyJobStatus', '');
                        }
                        setStaffRequirement(values.staffRequired);
                    }}
                >
                    <option value="1">1</option>
                    <option value="2">2</option>
                </Select>
            </div>
            <div className="v2__form-block">
                <div className="v2__form-group--pos-1-1">
                    <strong>Staff member #1</strong>
                </div>
                <HcpSelect
                    patient={values as VisitValuesType}
                    disabled={isHcp1SelectorDisabled}
                    includeUnavailableHcps={showUnavailableHcp}
                    ariaLabel="hcp select"
                />
                <Checkbox
                    name="Show unavailable"
                    className="v2__form-group--pos-2-2"
                    title="Show unavailable"
                    disabled={loading}
                    checked={showUnavailableHcp}
                    onChange={() => setShowUnavailableHcp(!showUnavailableHcp)}
                    aria-label="show unavailable hcps"
                />
                <ConditionalDisplay show={formMode === FormMode.EDIT_VISIT}>
                    <JobStatusDetails isBuddy={false} values={values} isEditMode={true} />
                </ConditionalDisplay>
            </div>
            <ConditionalDisplay show={values.staffRequired === 2}>
                <div className="v2__form-block">
                    <div className="v2__form-group--pos-1-1">
                        <strong>Staff member #2</strong>
                    </div>
                    <HcpSelect
                        patient={values as VisitValuesType}
                        disabled={isHcp2SelectorDisabled}
                        isFirstUser={false}
                        includeUnavailableHcps={showUnavailableBuddyHcp}
                        ariaLabel="buddy select"
                    />
                    <Checkbox
                        name="Show unavailable"
                        className="v2__form-group--pos-2-2"
                        title="Show unavailable"
                        disabled={loading}
                        checked={showUnavailableBuddyHcp}
                        onChange={() => setShowUnavailableBuddyHcp(!showUnavailableBuddyHcp)}
                        aria-label="show unavailable buddys"
                    />
                    <ConditionalDisplay show={formMode === FormMode.EDIT_VISIT}>
                        <JobStatusDetails isBuddy={true} values={values} isEditMode={true} />
                    </ConditionalDisplay>
                </div>
            </ConditionalDisplay>
            <div className="v2__form-block">
                <Select
                    name="careComplexity"
                    className="v2__form-group--pos-1-4"
                    label="Care complexity"
                    disabled={loading}
                    isContinueBtnClicked={isContinueBtnClicked}
                >
                    <option value="">Select...</option>
                    {Object.keys(friendlyCareComplexity)
                        .sort((a, b) => a.localeCompare(b))
                        .map((careComplexity) => (
                            <option key={careComplexity} value={careComplexity}>
                                {friendlyCareComplexity[careComplexity]}
                            </option>
                        ))}
                </Select>
                <Checkbox
                    name="carRequired"
                    className="v2__form-group--pos-2-4"
                    label="Transport requirements"
                    title="Car required"
                    disabled={loading}
                    checked={values.carRequired}
                />

                <DateInput
                    {...getMomentDateFormatter(dateTimeFormat)}
                    dateFormat={dateTimeFormat}
                    timePrecision={'minute'}
                    name={'earliestDateOfVisit'}
                    label={'Earliest time of job'}
                    maxDate={MAXDATE}
                    className="v2__form-group--pos-3-4"
                />
                <DateInput
                    {...getMomentDateFormatter(dateTimeFormat)}
                    dateFormat={dateTimeFormat}
                    timePrecision={'minute'}
                    name={'latestDateOfVisit'}
                    label={'Latest time of job'}
                    maxDate={MAXDATE}
                    className="v2__form-group--pos-4-4"
                />

                <ConditionalDisplay show={hasWarningMessages}>
                    <div className="v2__form-group--pos-1-1">
                        <WarningBanner spacerTop={true} compact={true} warnings={warningMessages} />
                    </div>
                </ConditionalDisplay>
                <label className="v2__form-group" htmlFor="submit">
                    <Button
                        className="v2__form-submit-button"
                        name={buttonName}
                        size={ButtonSizes.MEDIUM}
                        clickEvent={onSubmit}
                        type="submit"
                        testId="careDetailsSave"
                        id="submit"
                    />
                </label>
            </div>
        </>
    );
};

export default observer(CareDetails);
