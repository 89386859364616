import { LegendOptionV2 } from '@doc-abode/data-models';

import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

const DynamicLegendOption = ({ option }: { option: LegendOptionV2 }) => (
    <div className="ucr__legend-panel-sub-section" key={option.color}>
        <div
            className="ucr__legend-panel-square-box-size"
            style={{ backgroundColor: option.color }}
        ></div>
        <p className="ucr__legend-panel-label">{option.label}</p>
    </div>
);

export const LegendJobColours = () => {
    const {
        RootStore: {
            configStore: { pathways, adminTypes, legend },
        },
    } = useStores<{ RootStore: RootStore }>();

    const allServices = pathways.map((pathway: any) => pathway.services).flat();

    const visitLegend =
        legend.visit?.filter((option: any) =>
            allServices.some((service: any) => service.color === option.color && service.enabled),
        ) || [];

    const adminLegend =
        legend.admin?.filter((option: any) =>
            adminTypes.some((admin: any) => admin.color === option.color && admin.enabled),
        ) || [];

    return (
        <>
            <h4 className="ucr__legend-panel-titles">Visit colours</h4>
            {visitLegend.map((option: any) => (
                <DynamicLegendOption option={option} key={option.color} />
            ))}
            <div className="ucr__legend-panel-sub-section">
                <div className="ucr__legend-panel-square-box-any ucr__legend-panel-square-box-size"></div>
                <p className="ucr__legend-panel-label">Unresolved patient alert</p>
            </div>

            <h4 className="ucr__legend-panel-titles">Admin colours</h4>
            {adminLegend.map((option: any) => (
                <DynamicLegendOption option={option} key={option.color} />
            ))}
        </>
    );
};
